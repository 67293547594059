class AccordionHover {
    constructor() {
        this._AccordionJquery1();
    }
    _AccordionJquery1() {
        (function ($) {
            $('.accordion.hover .accordion-a.first').addClass('active').next().slideDown();
            $('.accordion.hover .accordion-a').click(function (j) {
                var dropDown = $(this).closest('.accordion-li').find('.accordion-content');

                $(this).closest('.accordion.hover').find('.accordion-content').not(dropDown).slideUp();

                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                } else {
                    $(this).closest('.accordion.hover').find('.accordion-a.active').removeClass('active');
                    $(this).addClass('active');
                }
                dropDown.stop(false, true).slideToggle();
                j.preventDefault();
            });
        })(jQuery);
    }
}

class AccordionClick {
    constructor() {
        this._AccordionJquery2();
    }
    _AccordionJquery2() {
        (function ($) {
            $('.accordion.click .first').addClass('active').next().slideDown();
            $('.accordion.click .accordion-a').click(function (j) {
                var dropDown = $(this).closest('.accordion-li').find('.accordion-content');

                $(this).closest('.accordion.click').find('.accordion-content').not(dropDown).slideUp();

                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                } else {
                    $(this).closest('.accordion.click').find('.accordion-a.active').removeClass('active');
                    $(this).addClass('active');
                }
                dropDown.stop(false, true).slideToggle();
                j.preventDefault();
            });
        })(jQuery);
    }
}







