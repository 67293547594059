class PuntosAnimados extends HTMLElement {
    constructor() {
        super()
    }
    
    connectedCallback() {
        setTimeout(this.set.bind(this), 1000)
        setTimeout(this.removeDisabled.bind(this), 1500)
      }

    set() {
       this.classList.add('animar') 
    }

    removeDisabled(){
        this.classList.add('d-none')
        document.querySelectorAll('.disabled').forEach(e => {
            e.classList.remove('disabled')
        })
    }
}

customElements.define("puntos-intro", PuntosAnimados);