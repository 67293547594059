class animacionNosotros {
    constructor(lienzo, container) {
        this.lienzo = lienzo;
        this.scroll = Scrollbar.get(container.filter('.js-smooth-scroll')[0]);
        this.somos = container[0].querySelector('#somos');
        this.puntos = container[0].querySelectorAll('.punto');
        this.capas = lienzo.querySelectorAll('.capa');
        const keyPunto1 = { transform: 'translate(687px, 181px)' };
        const keyPunto2 = { transform: 'translate(1215px, 423px)' };
        const keyPunto3 = { transform: 'translate(900px, 769px)' };
        const keyPunto4 = { transform: 'translate(254px, 518px)' };
        const timing = {
            duration: 1000,
            iterations: 1,
            fill: 'forwards',
        };
        this.animaciones = [];
        this.animaciones.push(lienzo.querySelector('#punto-1').animate(keyPunto1, timing));
        this.animaciones.push(lienzo.querySelector('#punto-2').animate(keyPunto2, timing));
        this.animaciones.push(lienzo.querySelector('#punto-3').animate(keyPunto3, timing));
        this.animaciones.push(lienzo.querySelector('#punto-4').animate(keyPunto4, timing));
        this.animaciones.forEach(animacion => animacion.pause());

        // Verificar si el objeto 'scroll' está definido antes de agregar el listener
        if (this.scroll !== undefined) {
            this.scroll.addListener(this.update.bind(this));
        }
    }

    update(e) {
            const tiempo = e.offset.y / (window.innerHeight);
            this.animaciones.forEach(animacion => animacion.currentTime = tiempo * 3000);
            this.capas.forEach(capa => {
                if (tiempo < 0.4) {
                    this.puntos.forEach(element => element.classList.remove('show'));
                    this.somos.classList.remove('claro');
                } else {
                    this.somos.classList.add('claro');
                    this.puntos.forEach(element => element.classList.add('show'));
                }
                if (tiempo < 1.2) {
                    this.lienzo.style.transform = (`translateY(${e.offset.y}px)`);
                }
            });
       
    }
}