class Grilla {
  constructor(scope) {
    this.initGrilla();
    this.addResizeListener();
  }

  initGrilla() {
    const items = document.querySelectorAll('.item');
    for (let i = 1; i < items.length; i++) {
      const prevItem = items[i - 1];
      const currentItem = items[i];
      const prevFigureContent = prevItem.querySelector('.figure-project__content');
      const altura = prevFigureContent.offsetHeight;
      currentItem.style.marginBottom = `-${altura}px`;
      currentItem.style.top = `-${altura}px`;
    }
  }

  addResizeListener() {
    window.addEventListener('resize', this.initGrilla.bind(this));
  }
}

const grilla = new Grilla();