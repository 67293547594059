class Tabs{
    constructor(scope){
        this.tabsLetras = scope[0].querySelectorAll('.tabs label')
        this.tabsPalabras = scope[0].querySelectorAll('.list-word label')
        this.initTabs(this.tabsLetras)
        this.initTabs(this.tabsPalabras)
    }

    initTabs(tabs){
        tabs.forEach(tab => tab.addEventListener('click', ()=> this.setActive(tabs, tab)));
    }

    setActive(list, elem){
        list.forEach(e=> e.classList.remove('activo'))
        elem.classList.add('activo')
    }
    
}