class Citas {
    constructor(scope) {
        scope[0].querySelectorAll('.cita-strong').forEach(container =>{
            const citas = container.querySelectorAll('strong')
            const descripciones = container.querySelectorAll('.descripcion')
            this.init(citas, descripciones)
        })
    }

    mostrar(ele, descripciones){
        descripciones.forEach(des => des.classList.add('ocultar'))
        descripciones[ele].classList.remove('ocultar')
    }
    
    init(citas, descripciones){   
        citas.forEach((cita, i )=> {
            cita.classList.add('mi-cita')
            cita.setAttribute('data-arts-cursor', true)
            cita.classList.add('js-arts-cursor-highlight')
            cita.classList.add('underline-hover-self')
            cita.setAttribute('number', i+1)
            cita.addEventListener('click', e => this.mostrar(i, descripciones))
        })
        descripciones.forEach((des, i)=>{
            des.querySelector('span').innerHTML = (i+1).toString()
            if (i>0) {
                des.classList.add('ocultar')
            }
        })
    }
  }
